<template>
  <b-modal
    id="otp-modal"
    title="Enter OTP"
    centered
    hide-footer
    size="md"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <div>
        <h2 class="m-0">Enter OTP</h2>
      </div>
    </template>
    <validation-observer ref="OtpFormValidation">
      <b-form @submit.prevent>
        <validation-provider
          #default="{ errors }"
          name="OTP"
          rules="required|integer|positive|length:6"
        >
          <b-form-group>
            <b-form-input id="plot_no" v-model="otp" placeholder="Enter OTP" />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <p class="text-danger border-danger rounded p-2"><feather-icon icon="AlertTriangleIcon" size="22" class="mr-1"/>Taking snapshots or screen captures of this portal is STRICTLY PROHIBITED.  Any violation of this policy will lead to initiation of disciplinary action against the offender.</p>

        <b-form-group>
          <b-button
            type="submit"
            variant="primary"
            pill
            class="mr-1 float-right"
            @click="validationForm"
          >
            Submit
          </b-button>
          <b-button
            type="button"
            variant="secondary"
            pill
            class="mr-1 float-right"
            @click="closeModal"
          >
            Back
          </b-button>
        </b-form-group>
        <span style="display: flex; flex-direction: row">
          <p style="margin-right: 10px">Didn't get the code?</p>
          <p
            v-if="secondsRemaining <= 0"
            v-bind:class="{ 'text-danger': secondsRemaining <= 0 }"
            style="cursor: pointer"
            @click="resend"
          >
            Resend
          </p>
          <p v-else>Resend ({{ secondsRemaining }})</p>
        </span>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { quillEditor } from "vue-quill-editor";
import util from "@/util.js";
import axios from "axios";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  mixins: [util],
  props: {
    cnic: String,
    password: String,
  },
  mounted() {
    setInterval(() => {
      this.secondsRemaining--;
    }, 1000); // 1 second
  },
  data() {
    return {
      required,
      otp: "",
      secondsRemaining: 60,
      // bookingRoles: [
      //   'bk__agm',
      //   'bk__mess_sec',
      //   'bk__fmn_adm'
      // ]
    };
  },
  methods: {
    ...mapActions({
      verifyOtp: "appData/verifyOtp",
      resendOtp: "appData/resendOtp",
    }),
    async validationForm() {
      const success = await this.$refs.OtpFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async closeModal() {
      this.$emit("modalClosed");
    },
    async resend() {
      try {
        const res = await this.resendOtp({ cnic: this.cnic });
        if ((res.status = 200)) {
          this.$swal({
            title: "OTP resent",
            icon: "success",
            timer: 500,
          });
          this.secondsRemaining = 60
        } else {
          throw new Error("OTP resend failed");
        }
      } catch (error) {
        this.$emit("modalClosed");
        this.displayError(error);
      }
    },
    async submit() {
      try {
        if (this.otp) {
          const res = await this.verifyOtp({ otp: this.otp, cnic: this.cnic });
          if ((res.status = 200)) {
            // this.$swal({
            //   title: "Login successfully",
            //   icon: "success",
            // });
            this.$store.dispatch('appData/loginUser')
            this.$emit("modalClosed");
            // if( this.bookingRoles.includes(this.getUser.role_data.code_name) ){
            if(this.getUser.role_data.code_name.includes('bk__')){
              this.$router.push({ name: "Booking" });
            }
            else{
              this.$router.push({ name: "Home" });
            }
          } else {
            throw new Error("Invalid OTP");
          }
        }
        this.$emit("modalClosed");
      } catch (error) {
        this.$emit("modalClosed");
        this.displayError(error);
      }
    },
  },
    computed: {
        ...mapGetters({
            getUser: "appData/getUser",
            hasPermisson: "appData/hasPermission",
            hasRole: "appData/hasRole",
        }),
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
