<template>
  <b-modal
    id="forgot-password-modal"
    title="Reset Password"
    centered
    hide-footer
    size="md"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <div>
        <h2 class="m-0">Reset Password</h2>
      </div>
    </template>
    <span v-if="otpsent === 0">
      <validation-observer ref="CNICFormValidation">
        <b-form @submit.prevent>
          <b-form-group>
            <label for="cnic" style="width: 100%">
              <div class="d-flex justify-content-between">
                <div class="d-inline-block">
                  <span style="color:black">CNIC </span><span class="text-danger"> *</span>
                </div>
              </div>
            </label>

            <validation-provider
              #default="{ errors }"
              name="CNIC"
              rules="required|integer|length:13"
            >
              <b-form-input
                id="cnic"
                v-model="cnic"
                :state="errors.length > 0 ? false : null"
                name="cnic"
                placeholder="61101XXXXXXXX"
                
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <b-button
              type="submit"
              variant="primary"
              pill
              class="mr-1 float-right"
              @click="validationForm"
            >
              Send OTP
            </b-button>
            <b-button
              type="button"
              variant="secondary"
              pill
              class="mr-1 float-right"
              @click="closeModal"
            >
              Back
            </b-button>
          </b-form-group>
        </b-form>
      </validation-observer>
    </span>
    <span v-else>
      <validation-observer ref="ResetPasswordFormValidation">
        <b-form @submit.prevent>
          <b-form-group>
            <label for="otp" style="width: 100%">
              <div class="d-flex justify-content-between">
                <div class="d-inline-block">
                  <span>OTP </span><span class="text-danger"> *</span>
                </div>
              </div>
            </label>
            <validation-provider
              #default="{ errors }"
              name="OTP"
              rules="required|integer|length:6"
            >
              <b-form-input
                id="otp"
                v-model="otp"
                :state="errors.length > 0 ? false : null"
                name="OTP"
                placeholder="XXXXXX"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="password" style="width: 100%">
              <div class="d-flex justify-content-between">
                <div class="d-inline-block">
                  <span>Password </span><span class="text-danger"> *</span>
                </div>
              </div>
            </label>

            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required|password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="password"
                  placeholder="Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="confirm-password" style="width: 100%">
              <div class="d-flex justify-content-between">
                <div class="d-inline-block">
                  <span>Confirm Password </span
                  ><span class="text-danger"> *</span>
                </div>
              </div>
            </label>

            <validation-provider
              #default="{ errors }"
              name="Confirm Password"
              rules="required|confirmed:Password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="c-password"
                  v-model="passwordCon"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordConFieldType"
                  name="password"
                  placeholder="Re-type Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordConToggleIcon"
                    @click="togglePasswordConVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small  v-if="password !== passwordCon" class="text-danger">{{ customErrorMessage }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <b-button
              type="submit"
              variant="primary"
              pill
              class="mr-1 float-right"
              @click="passwordValidationForm"
            >
              Reset Password
            </b-button>
            <b-button
              type="button"
              variant="secondary"
              pill
              class="mr-1 float-right"
              @click="closeModal"
            >
              Back
            </b-button>
          </b-form-group>
        </b-form>
      </validation-observer>
    </span>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { quillEditor } from "vue-quill-editor";
import util from "@/util.js";
import axios from "axios";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  mixins: [util],

  mounted() {},
  data() {
    return {
      required,
      show:false,
      otp: "",
      cnic: "",
      otpsent: 0,
      password: "",
      passwordCon: "",
      customErrorMessage: 'Password do not match',
      passwordFieldType: "password",
      passwordConFieldType: "password",
    };
  },
  methods: {
    ...mapActions({
      verifyOtp: "appData/verifyOtp",
      resendOtp: "appData/resendOtp",
      sendOtp: "appData/sendOtp",
      resetPassword: "appData/resetPassword",
    }),
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    togglePasswordConVisibility() {
      this.passwordConFieldType =
        this.passwordConFieldType === "password" ? "text" : "password";
    },
    async validationForm() {
      const success = await this.$refs.CNICFormValidation.validate();
      if (success) {
        await this.sendOtpClk();
      }
    },
    async passwordValidationForm() {
      const success = await this.$refs.ResetPasswordFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async closeModal() {
      this.$emit("forgotModalClosed");
    },
    async sendOtpClk() {
      this.show = true;
      try {
        const res = await this.sendOtp({ cnic: this.cnic });
        if (res.status === 200) {
          
          this.$refs.CNICFormValidation.reset();
          this.otpsent = 1;
          this.displaySuccess(
            "OTP sent successfuly to registered mobile number"
          );
        }
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    async submit() {
      this.show = true;
      try {
        const res = await this.resetPassword({
          otp: this.otp,
          cnic: this.cnic,
          password: this.password,
        });
        if (res.status === 200) {
          this.displaySuccess("Password reset successsfully");
          this.closeModal();
          this.otpsent = 0;
        }
        this.show = false;
      } catch (error) {
        this.show = false;
        //this.closeModal();
        this.displayError(error);
      }
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordConToggleIcon() {
      return this.passwordConFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";
::placeholder {
  color: black; 
}
</style>


