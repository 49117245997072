var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"forgot-password-modal","title":"Reset Password","centered":"","hide-footer":"","size":"md","no-close-on-esc":true,"no-close-on-backdrop":true},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',[_c('h2',{staticClass:"m-0"},[_vm._v("Reset Password")])])]},proxy:true}])},[(_vm.otpsent === 0)?_c('span',[_c('validation-observer',{ref:"CNICFormValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',[_c('label',{staticStyle:{"width":"100%"},attrs:{"for":"cnic"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-inline-block"},[_c('span',{staticStyle:{"color":"black"}},[_vm._v("CNIC ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])])]),_c('validation-provider',{attrs:{"name":"CNIC","rules":"required|integer|length:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cnic","state":errors.length > 0 ? false : null,"name":"cnic","placeholder":"61101XXXXXXXX"},model:{value:(_vm.cnic),callback:function ($$v) {_vm.cnic=$$v},expression:"cnic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3701307741)})],1),_c('b-form-group',[_c('b-button',{staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary","pill":""},on:{"click":_vm.validationForm}},[_vm._v(" Send OTP ")]),_c('b-button',{staticClass:"mr-1 float-right",attrs:{"type":"button","variant":"secondary","pill":""},on:{"click":_vm.closeModal}},[_vm._v(" Back ")])],1)],1)],1)],1):_c('span',[_c('validation-observer',{ref:"ResetPasswordFormValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',[_c('label',{staticStyle:{"width":"100%"},attrs:{"for":"otp"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-inline-block"},[_c('span',[_vm._v("OTP ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])])]),_c('validation-provider',{attrs:{"name":"OTP","rules":"required|integer|length:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"otp","state":errors.length > 0 ? false : null,"name":"OTP","placeholder":"XXXXXX"},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{staticStyle:{"width":"100%"},attrs:{"for":"password"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-inline-block"},[_c('span',[_vm._v("Password ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])])]),_c('validation-provider',{attrs:{"name":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{staticStyle:{"width":"100%"},attrs:{"for":"confirm-password"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-inline-block"},[_c('span',[_vm._v("Confirm Password ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])])]),_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"c-password","state":errors.length > 0 ? false : null,"type":_vm.passwordConFieldType,"name":"password","placeholder":"Re-type Password"},model:{value:(_vm.passwordCon),callback:function ($$v) {_vm.passwordCon=$$v},expression:"passwordCon"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordConToggleIcon},on:{"click":_vm.togglePasswordConVisibility}})],1)],1),(_vm.password !== _vm.passwordCon)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.customErrorMessage))]):_vm._e()]}}])})],1),_c('b-form-group',[_c('b-button',{staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary","pill":""},on:{"click":_vm.passwordValidationForm}},[_vm._v(" Reset Password ")]),_c('b-button',{staticClass:"mr-1 float-right",attrs:{"type":"button","variant":"secondary","pill":""},on:{"click":_vm.closeModal}},[_vm._v(" Back ")])],1)],1)],1)],1),_c('b-overlay',{attrs:{"show":_vm.show,"spinner-variant":"primary","no-wrap":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }